import React from "react"
import PropTypes from "prop-types"

import InputStyled from "../styled/InputStyled"

const RadioItem = ({
  name, groupId, text, value, onChange, checked
}) => {
  const contextId = groupId && `-${groupId}`
  const id = `${name}${contextId}-${value}`

  return (
    <InputStyled.GroupLabelItem>
      {text}
      <InputStyled.Radio
        onChange={(e) => onChange(+e.target.value)}
        id={id}
        name={`${name}${contextId}-group`}
        type="radio"
        value={value}
        checked={checked}
      />
      <InputStyled.Circle />
    </InputStyled.GroupLabelItem>
  )
}

RadioItem.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  groupId: PropTypes.string,
  checked: PropTypes.bool.isRequired
}

export default RadioItem
