import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import * as cookie from "./cookie"

const resources = {
  ru: {
    translation: {
      ChoosePlan_Title: "",
      ChoosePlan_Buy: "Купить",
      ChoosePlan_Custom: "Мульти\nабонемент",
      ChoosePlan_Description: "",

      Form_Title: "",
      Form_Description: "",

      Headline_Title: "",
      Headline_Description: "",

      Checkout_RangePeriod1: "месяц",
      Checkout_RangePeriod2: "2 месяца",
      Checkout_RangePeriod3: "3 месяца",
      Checkout_RangePeriod4: "4 месяца",
      Checkout_RangePeriod5: "5 месяцев",
      Checkout_RangePeriod6: "6 месяцев",
      Checkout_RangePeriod7: "год",
      Checkout_RangePeriod8: "2 года",
      Checkout_PlaceholderDescription: "Комментарий",

      Account_Device1: "Приставка MAG/AURA",
      Account_Device2: "Приложение Stalker TV (iPad, iPhone, Apple TV)",
      Account_Device3: "Приложение Prosto TV (Smart TV, Android, Dune, Windows, TVIP, Amazon Fire TV и т.д.)",
      Account_Create: "Создать аккаунт",
      Account_Login: "Логин",
      Account_EndDate: "Дата окончания",
      Account_NotFound: "Аккаунт не существует",
      Account_MultiPaymentNotAvailable: "Мульти платеж недоступен",
      Account_Processing: "Загрузка",
      Account_Apply: "Применить",

      Account_black_friday_1: "Получите годовую подписку со скидкой 30% — всего за 70€.",
      Account_black_friday_2: "Акция действует до 30 ноября. Не пропустите!",
      Account_new_register_1: "Получите годовую подписку со скидкой 30% — всего за 70€.",
      Account_new_register_2: "Акция действует до 31 декабря. Не пропустите!",

      Constructor_EmailError: "Неверная электронная почта",
      Constructor_SuccessMessage: "Платеж был отправлен. Ваша подписка будет продлена через несколько минут",
      Constructor_AddAccount: "Добавить аккаунт",
      Constructor_WishMessage: "Приятного просмотра",
      Constructor_DuplicateAccount: "Логины должны быть разными",
      Constructor_EmptyAccount: "Заполните это поле",

      Header_Logo: "ProstoTV - телевидение для всей семьи",

      Speed_Start: "Начать",
      Speed_Tip: "Рекомендуемое разрешение видеоконтента",
      Speed_Server1: "Германия 1",
      Speed_Server2: "Германия 2",
      Speed_Server3: "Франция",
      Speed_Server4: "Канада",
      Speed_Server5: "Кинозал",
      Speed_Incompatible: "Скорости до этого сервера не хватает для просмотра ТВ",
      Speed_Mbps: "Мбит/с"
    }
  },
  uk: {
    translation: {
      ChoosePlan_Title: "",
      ChoosePlan_Buy: "Купити",
      ChoosePlan_Custom: "Мульти\nабонемент",
      ChoosePlan_Description: "",

      Form_Title: "",
      Form_Description: "",

      Headline_Title: "",
      Headline_Description: "",

      Checkout_RangePeriod1: "місяць",
      Checkout_RangePeriod2: "2 місяці",
      Checkout_RangePeriod3: "3 місяці",
      Checkout_RangePeriod4: "4 місяці",
      Checkout_RangePeriod5: "5 місяців",
      Checkout_RangePeriod6: "6 місяців",
      Checkout_RangePeriod7: "рік",
      Checkout_RangePeriod8: "2 роки",
      Checkout_PlaceholderDescription: "Примітка",

      Account_Device1: "Приставка MAG/AURA",
      Account_Device2: "Додаток Stalker TV (iPad, iPhone, Apple TV)",
      Account_Device3: "Додаток Prosto TV (Smart TV, Android, Dune, Windows, TVIP, Amazon Fire TV і т.д.)",
      Account_Create: "Створити акаунт",
      Account_Login: "Логін",
      Account_EndDate: "Дата закінчення",
      Account_NotFound: "Аккаунт не існує",
      Account_MultiPaymentNotAvailable: "Мульти платіж недоступний",
      Account_Processing: "Завантаження",
      Account_Apply: "Застосувати",

      Account_black_friday_1: "Отримайте річний абонемент зі знижкою 30% — всього за 70€.",
      Account_black_friday_2: "Акція діє до 30 листопада. Не пропустіть!",
      Account_new_register_1: "Отримайте річний абонемент зі знижкою 30% — всього за 70€.",
      Account_new_register_2: "Акція діє до 31 грудня. Не пропустіть!",

      Constructor_EmailError: "Невірна електронна пошта",
      Constructor_SuccessMessage: "Платіж було відправлено. Вашу підписку буде продовжено за кілька хвилин",
      Constructor_AddAccount: "Додати акаунт",
      Constructor_WishMessage: "Приємного перегляду",
      Constructor_DuplicateAccount: "Логіни повинні бути різними",
      Constructor_EmptyAccount: "Заповніть це поле",

      Header_Logo: "ProstoTV - телебачення для всієї родини",

      Speed_Start: "Розпочати",
      Speed_Tip: "Рекомендована роздільна здатність відеовмісту",
      Speed_Server1: "Німеччина 1",
      Speed_Server2: "Німеччина 2",
      Speed_Server3: "Франція",
      Speed_Server4: "Канада",
      Speed_Server5: "Кінозал",
      Speed_Incompatible: "Швидкість до цього сервера недостатня для перегляду ТБ",
      Speed_Mbps: "Мбіт/с"
    }
  }
}

const navLang = navigator.language.split("-")[0]
const lang = cookie.get("language")
  || (["uk", "ru"].includes(navLang) ? navLang : "ru")

document.cookie = `language=${lang}`

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: lang,
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
