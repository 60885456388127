import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"

import InputStyled from "../../common/styled/InputStyled"
import AlertStyled from "../../common/styled/AlertStyled"
import s from "./ConstructorStyled"

import RangeItem from "../../common/component/RangeItem"
import PaypalContainer from "../../paypal/PaypalContainer"
import { accountPropTypes } from "./ConstructorModel"

function Checkout({
  period, totalPrice, email, errors, accounts, formValid, periods,
  textChanged, periodChanged, orderCompleted, emailChange
}) {
  const { t } = useTranslation()

  return (
    <s.BlockContainer>
      <RangeItem
        name="period"
        text={t(periods.find(({ id }) => id === period).name)}
        onChange={periodChanged}
        value={period}
      />

      <s.ControlWrapper>
        <InputStyled.Text
          name="email"
          type="email"
          placeholder="Email"
          onChange={(e) => emailChange(e.target.name, e.target.value)}
        />
        {(!errors.email.isValid && errors.email.message)
          && (
            <AlertStyled.Inline
              severity="error"
            >
              {t(errors.email.message)}
            </AlertStyled.Inline>
          )}
      </s.ControlWrapper>

      <InputStyled.MultilineText
        name="description"
        placeholder={t("Checkout_PlaceholderDescription")}
        onChange={(e) => textChanged(e.target.name, e.target.value)}
      />

      <s.Price discount={(!!periods.find(({ id }) => id === period).discount).toString()}>
        <sup>€</sup>
        <span>{totalPrice}</span>
      </s.Price>

      <s.PayPalWrapper
        valid={formValid.toString()}
      >
        <PaypalContainer
          {...{
            email, period, accounts, orderCompleted, totalPrice
          }}
        />

        {!formValid && <s.PayPalOverlay />}
      </s.PayPalWrapper>
    </s.BlockContainer>
  )
}

Checkout.propTypes = {
  countAccounts: PropTypes.number.isRequired,
  period: PropTypes.number.isRequired,
  totalPrice: PropTypes.number.isRequired,
  email: PropTypes.string,
  errors: PropTypes.shape({
    email: PropTypes.shape({
      isValid: PropTypes.bool.isRequired,
      message: PropTypes.string
    })
  }).isRequired,
  accounts: PropTypes.arrayOf(accountPropTypes).isRequired,
  formValid: PropTypes.bool.isRequired,
  periods: PropTypes.arrayOf(PropTypes.shape({})),

  periodChanged: PropTypes.func.isRequired,
  textChanged: PropTypes.func.isRequired,
  orderCompleted: PropTypes.func.isRequired,
  emailChange: PropTypes.func.isRequired
}

export default Checkout

