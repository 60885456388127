import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"

import s from "./SpeedStyled"

export function Meter(props) {
  const { value } = props
  const { t } = useTranslation()

  const minValue = 0
  const maxValue = 100
  const size = 240
  const center = size / 2
  const strokeWidth = 26
  const r = center - strokeWidth
  const c = 2 * r * Math.PI
  const a = c * (220 / 360)
  const percentage = (Math.min(value, 100) - minValue) / (maxValue - minValue)
  const offset = c - percentage * a

  return (
    <s.Meter>
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        strokeWidth={strokeWidth}
      >
        <circle
          cx={center}
          cy={center}
          r={r}
          stroke="rgba(255, 176, 0, 0.83)"
          strokeOpacity={0.2}
          strokeDasharray={`${a} ${c}`}
          strokeLinecap="round"
          transform={`rotate(160 ${center} ${center})`}
        />

        <circle
          cx={center}
          cy={center}
          r={r}
          stroke="rgba(255, 176, 0, 0.83)"
          strokeDasharray={c}
          strokeDashoffset={offset}
          strokeLinecap="round"
          transform={`rotate(160 ${center} ${center})`}
        />

        <text
          x={center}
          y={center}
          fontFamily="Lato,sans-serif"
          fontSize={50}
          fontWeight="bold"
          textAnchor="middle"
          fill="#444"
        >
          {Number(value).toFixed(1)}
        </text>

        <text
          x={center}
          y={center + 40}
          fontFamily="Lato,sans-serif"
          fontSize={18}
          fontWeight="bold"
          textAnchor="middle"
          fill="#444"
          fillOpacity={0.8}
        >
          {t("Speed_Mbps")}
        </text>
      </svg>
    </s.Meter>
  )
}

Meter.propTypes = {
  value: PropTypes.number.isRequired,
}

export default Meter
