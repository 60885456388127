import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"

import { accountPropTypes } from "../form/constructor/ConstructorModel"

const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM })

function PayPalContainer(props) {
  async function onApprove(data, actions) {
    const { orderCompleted } = props

    actions.order.capture().then(orderCompleted)
  }

  async function createOrder(data, actions) {
    const {
      totalPrice, email, accounts, period
    } = props

    const averageItemPrice = (totalPrice / accounts.length).toFixed(2)

    const order = actions.order.create({
      payer: {
        email_address: email
      },
      purchase_units: [{
        amount: {
          value: totalPrice,
          breakdown: {
            item_total: {
              currency_code: "EUR",
              value: totalPrice
            }
          }
        },
        items: Array.from(accounts).map(({ login, mac, device }) => ({
          name: login || mac,
          description: device,
          quantity: "1",
          unit_amount: {
            currency_code: "EUR",
            value: averageItemPrice
          },
        })),
        custom_id: btoa(JSON.stringify(
          { price: totalPrice, period }
        )).replace(/=/g, "")
      }]
    })

    return order
  }

  return (
    <PayPalButton
      createOrder={createOrder}
      onApprove={onApprove}
    />
  )
}

PayPalContainer.propTypes = {
  totalPrice: PropTypes.number.isRequired,
  email: PropTypes.string,
  accounts: PropTypes.arrayOf(accountPropTypes).isRequired,
  period: PropTypes.number.isRequired,

  orderCompleted: PropTypes.func.isRequired
}

export default PayPalContainer
