import React from "react"
import ReactDOM from "react-dom/client" // Updated import for React 18
import { legacy_createStore as createStore, applyMiddleware, combineReducers } from "redux"
import { Provider } from "react-redux"
import { thunk } from "redux-thunk"

import "./index.css"
import "./utils/i18n.utils"

import App from "./components/app/App"

import * as serviceWorker from "./serviceWorker"
import * as reducers from "./store/reducers"

const store = createStore(combineReducers(reducers), applyMiddleware(thunk))

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)

serviceWorker.unregister()
