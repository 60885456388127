import * as types from "./types"

const selectPlanAction = (plan) => async (dispatch) => dispatch({ type: types.PLAN_SELECTED, plan })

const changePeriodAction = (id) => async (dispatch) => dispatch({ type: types.CHANGE_PERIOD, id })

const changeTotalPriceAction = (val) => async (dispatch) => dispatch({ type: types.CHANGE_TOTAL_PRICE, val })

const changePriceAction = (price, plan) => async (dispatch) => dispatch({ type: types.CHANGE_PRICE, price, plan })

export {
  selectPlanAction,
  changePeriodAction,
  changeTotalPriceAction,
  changePriceAction
}
