import _ from "lodash"

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, shallowEqual, useSelector } from "react-redux"

import { useTranslation } from "react-i18next"

import {
  changePeriodAction,
  changePriceAction,
} from "../../../store/subscription/actions"

import {
  checkLoginAction,
  transactionCompleteAction,
  getIpAction,
} from "../../../store/pay/actions"

import Button from "../../common/styled/ButtonStyled"

import s from "./ConstructorStyled"

import Account from "./Account"
import Checkout from "./Checkout"

import { accountModel } from "./ConstructorModel"

let timeout, abortController

function ConstructorContainer(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {
    changePeriod,
    checkLogin,
    transactionComplete,
    getIp,
    changePrice,
    plan,
    pricesData,
    periods,
  } = useSelector((state) => ({
    changePeriod: changePeriodAction,
    checkLogin: checkLoginAction,
    transactionComplete: transactionCompleteAction,
    getIp: getIpAction,
    changePrice: changePriceAction,
    plan: state.subscription.plan,
    pricesData: state.subscription.pricesData,
    periods: state.subscription.periods
  }), shallowEqual)

  const [accounts, setAccounts] = useState([{ ...accountModel }])
  const [checkout, setCheckout] = useState({ description: "" })
  const [errors, setErrors] = useState({
    email: {
      isValid: false,
    },
  })
  const [formValid, setFormValid] = useState(false)
  const [transaction, setTransaction] = useState({})

  useEffect(() => {
    const element = document.getElementById("constructor-container")
    if (element) {
      element.scrollIntoView({ behavior: "smooth" })
    }
  }, [])

  async function eventChangeDevice(id, device) {
    const account = accounts.find((it) => it.id === id)

    account.device = device
    delete account.login
    delete account.mac

    setAccounts([...accounts])
  }

  async function eventAddAccount() {
    accounts.push({
      ..._.cloneDeep(accountModel),
      error: {},
      id: accounts.length,
    })

    setAccounts(accounts)
    validatePayPalButtons()
  }

  async function eventRemoveAccount(id) {
    setAccounts(accounts.filter((account) => account.id !== id))
    validatePayPalButtons()
  }

  async function eventChangePeriod(id) {
    await dispatch(changePeriod(id))

    setCheckout({ ...checkout, period: id })
  }

  function calculatePrice() {
    const { differentIp } = checkout

    const periodPrice = periods.find(({ id }) => id === plan.period.id).value

    let price = periodPrice * accounts.length

    if (!differentIp && accounts.length > 1) {
      price -= periodPrice * 0.5
    }

    if (!differentIp && accounts.length > 2) {
      price -= periodPrice * 0.7
    }

    return price
  }

  async function eventAccountChangeText(id, name, value) {
    const account = accounts.find((a) => a.id === id)

    account[name] = value
    account.error.message = "Account_Processing"
    account.error.isValid = false
    account.error.processing = true

    setAccounts([...accounts])
    setFormValid(false)
  }

  async function eventCheckoutChangeText(name, value) {
    checkout[name] = value

    setCheckout({ ...checkout })
  }

  async function eventEmailChangeText(name, value) {
    eventCheckoutChangeText(name, value)

    errors.email.message = "Constructor_EmailError"
    // eslint-disable-next-line no-control-regex
    errors.email.isValid = /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(value)

    setErrors(errors)
    validatePayPalButtons()
  }

  async function eventLoginChangeText(id, name, value) {
    eventAccountChangeText(id, name, value)

    window.clearTimeout(timeout)
    timeout = setTimeout(() => validateAccounts(id, name, value), 750)
  }

  async function validateAccounts(id, name, value) {
    const account = accounts.find((a) => a.id === id)

    let isValid = true

    if (isValid) {
      isValid = !!(account.login || account.mac)
      if (!isValid) account.error.message = "Constructor_EmptyAccount"
    }

    if (accounts.length > 1 && isValid) {
      const names = accounts
        .filter((f) => f.id !== account.id)
        .map(({ login, mac, device }) => (device === 1 ? mac : login))

      isValid = !names.includes(value)
      if (!isValid) account.error.message = "Constructor_DuplicateAccount"
    }

    if (isValid) {
      abortController && abortController.abort()
      abortController = new AbortController()

      account.checkLogin = await dispatch(checkLogin(value, account.device, abortController.signal))
      const { exist } = account.checkLogin || {}
      if (!exist) {
        isValid = false
        account.error.message = "Account_NotFound"
      }
    }

    if (accounts.length > 1 && isValid) {
      const resellerNames = accounts
        .filter((a) => a.checkLogin)
        .map((a) => a.checkLogin.resellerName)
      const uniqNames = _.uniq(resellerNames)

      isValid = uniqNames.length === 1
      if (!isValid) account.error.message = "Account_MultiPaymentNotAvailable"
    }

    if (accounts.length > 1 && isValid) {
      const connectionDates = accounts
        .filter((a) => a.checkLogin && a.checkLogin.connectionDate)
        .map((a) => a.checkLogin.connectionDate)
      const uniqConnectionDate = _.uniq(connectionDates)

      isValid = uniqConnectionDate.length < 2
      if (!isValid) account.error.message = "Account_MultiPaymentNotAvailable"
    }

    const { endDate } = account.checkLogin || {}

    if (isValid) {
      account.error.message = "Account_EndDate"
      account.error.date = endDate
    } else {
      account.checkLogin = {}
    }

    if (periods.some(p => p.discount)) {
      await dispatch(changePrice({ id: 4, period: { id: 7, value: 100, discount: false } }, pricesData.find((p) => p.id === 1)))
    }

    account.error.isValid = isValid
    account.error.processing = false

    setAccounts([...accounts])
    validatePayPalButtons()
  }

  async function eventApplySale(id) {
    const account = accounts.find((a) => a.id === id)
    const { saleType } = account.checkLogin

    if (saleType === "black_friday" || saleType === "new_register") {
      await dispatch(changePrice({ id: 4, period: { id: 7, value: 70, discount: true } }, pricesData.find((p) => p.id === 4)))
    }
  }

  async function validatePayPalButtons() {
    const ips = accounts.filter((f) => f.checkLogin).map((m) => m.checkLogin.ip)

    checkout.differentIp = !(
      ips.length > 1
      /*
        TODO: issue with client dynamic IP
        && ips.every((ip) => ip === ips[0])
      */
    )

    const mergeErrors = { ...errors }
    accounts.forEach((f, i) => { mergeErrors[`account_${i + 1}`] = { isValid: f.error.isValid } })

    const isValid = Object.values(mergeErrors).every((v) => v.isValid)

    setCheckout({ ...checkout })
    setFormValid(isValid)
  }

  async function eventCompleteOrder({ id }) {
    const { description, differentIp, email } = checkout

    const { ip } = await dispatch(getIp())

    const transaction = await dispatch(transactionComplete({
      id,
      differentIp,
      description,
      ip,
      email,
      names: accounts.map(({ login, mac }) => login || mac).join(" ")
    }))

    setTransaction(transaction)
  }

  return (
    <s.ConstructorContainer id="constructor-container">
      {(!_.isEmpty(transaction) && transaction.status) && (
        <s.BlockContainer>
          <s.EndUserDateContainer>
            {t("Constructor_SuccessMessage")}

            <s.WishMessage>{t("Constructor_WishMessage")}</s.WishMessage>
          </s.EndUserDateContainer>
        </s.BlockContainer>
      )}

      {(!_.isEmpty(transaction) && !transaction.status) && (
        <s.BlockContainer>
          {transaction.error}
        </s.BlockContainer>
      )}

      {(_.isEmpty(transaction)) && (
        <>
          {accounts.map((account) => (
            <Account
              {...account}
              device={account.device}
              countAccounts={accounts.length}
              key={account.id}
              deviceChanged={eventChangeDevice}
              accountRemoved={eventRemoveAccount}
              loginChanged={eventLoginChangeText}
              applySale={eventApplySale}
            />
          ))}

          {(accounts.length < 3 && plan.id === 6)
            && (
              <Button.Base center="true" onClick={() => eventAddAccount()}>
                {t("Constructor_AddAccount")}
              </Button.Base>
            )}

          <Checkout
            {...{ errors, accounts, formValid, periods }}
            countAccounts={accounts.length}
            period={plan.period.id}
            email={checkout.email}
            totalPrice={calculatePrice()}
            periodChanged={eventChangePeriod}
            textChanged={eventCheckoutChangeText}
            orderCompleted={eventCompleteOrder}
            emailChange={eventEmailChangeText}
          />

        </>
      )}
    </s.ConstructorContainer>
  )
}

ConstructorContainer.propTypes = {
  plan: PropTypes.shape(),
  transaction: PropTypes.shape({
    status: PropTypes.bool,
    error: PropTypes.string
  })
}

export default ConstructorContainer
