import React, { Component } from "react"
import i18n from "i18next"

import s from "./FooterStyled"

import * as cookie from "../../utils/cookie"

export default class FooterContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      lang: cookie.get("language")
    }
  }

  selectLang = (lang) => {
    document.cookie = `language=${lang}`
    i18n.changeLanguage(lang)
    this.setState({ lang })
  }

  render() {
    const { lang } = this.state

    return (
      <s.FooterContainer>
        <s.Copyright>
            © prostotv.com 2014 -
          {" "}
          {new Date().getFullYear()}
        </s.Copyright>

        <s.Lang>
          <s.UA
            onClick={() => this.selectLang("uk")}
            selected={lang !== "uk" && s.Selected}
          >
            UA
          </s.UA>
          <s.RU
            onClick={() => this.selectLang("ru")}
            selected={lang !== "ru" && s.Selected}
          >
            RU
          </s.RU>
        </s.Lang>
      </s.FooterContainer>
    )
  }
}
