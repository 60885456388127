import PayApi from "../../service/PayApi"

const checkLoginAction = (name, device, signal) => async (dispatch) => {
  let result = null

  try {
    result = await PayApi.checkLogin(name, device, signal)

    if (result.endDate) {
      const date = new Date(Date.parse(result.endDate.replace(/-/g, "/")))
      result.endDate = date.toLocaleDateString("ru-RU", {
        year: "numeric", month: "2-digit", day: "2-digit"
      })
    }
  } catch (err) {
    console.log(err)
    // dispatch({ type: actionTypes.ALERT_ERROR, payload: err.error })
  }

  return result
}

const transactionCompleteAction = (order) => async (dispatch) => {
  let result = null

  try {
    result = await PayApi.transaction(order)
  } catch (err) {
    console.log(err)
    // dispatch({ type: actionTypes.ALERT_ERROR, payload: err.error })
  }

  return result
}

const getIpAction = () => async (dispatch) => {
  let result = null

  try {
    result = await PayApi.ip()
  } catch (err) {
    console.log(err)
    // dispatch({ type: actionTypes.ALERT_ERROR, payload: err.error })
  }

  return result
}

export {
  checkLoginAction,
  transactionCompleteAction,
  getIpAction,
}
