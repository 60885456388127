import styled, { css } from "styled-components"

import { isMobile, isDesktop } from "../../utils/responsive"

import color from "../common/styled/ColorStyled"
import button from "../common/styled/ButtonStyled"

const lang = css`
  padding: 6px 10px;
  border-radius: 4px;
  ${({ selected }) => selected};
`

export default {
  FooterContainer: styled.div`
    padding: 45px 0;
    background: #444444;
    ${color.Text.White};
  `,
  Copyright: styled.div`
    text-align: center;
  `,
  Lang: styled.div`

    ${isDesktop && css`
      float: right;
      position: relative;
      top: -20px;
      right: 40px;
    `}

    ${isMobile && css`
      margin-top: 16px;
      text-align: center;
    `}
  `,
  UA: styled(button.Base)`
    ${lang};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    display: inline-block;
  `,
  RU: styled(button.Base)`
    ${lang};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    display: inline-block;
  `,
  Selected: css`
    background-color: rgb(250,251,253);
    ${color.Text.Dark};
  `,
}
