import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"

import Meter from "./Meter"

import s from "./SpeedStyled"

const Card = ({
  data: {
    id, name
  },
  selectCard,
  selected,
  tabSelected,
  tabSpeed,
  lock,
  recommendResolution
}) => {
  const { t } = useTranslation()

  const resolution = recommendResolution.filter((rr) => rr.value < tabSpeed[id]).pop()
  return (
    <s.CardContainer
      onClick={() => selectCard(id)}
      focus={selected.toString()}
      lock={lock.toString()}
    >
      <s.Name
        bottom={[1, 2].includes(tabSelected[id]).toString()}
      >
        {name && t(name).toUpperCase()}
      </s.Name>

      {(!tabSelected[id] || tabSelected[id] === 0) && (
        <s.Start>{t("Speed_Start")}</s.Start>
      )}

      {tabSelected[id] === 1 && (
        <>
          <Meter value={(tabSpeed[id] || 0)} />
        </>
      )}

      {tabSelected[id] === 2 && (
        <>
          <s.Speed>
            {Number(tabSpeed[id]).toFixed(1)}
            {" "}
            {t("Speed_Mbps")}
          </s.Speed>

          {!resolution && (
            <s.Incompatible>{t("Speed_Incompatible")}</s.Incompatible>
          )}

          {resolution && (
            <>
              <s.Tip>{t("Speed_Tip")}</s.Tip>
              <s.Resolution>
                {recommendResolution.filter((rr) => rr.value < tabSpeed[id]).pop().name}
              </s.Resolution>
            </>
          )}

        </>
      )}
    </s.CardContainer>

  )
}

Card.propTypes = {
  data: PropTypes.shape().isRequired,

  selectCard: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,

  tabSelected: PropTypes.arrayOf(PropTypes.number).isRequired,
  tabSpeed: PropTypes.arrayOf(PropTypes.number).isRequired,
  lock: PropTypes.bool.isRequired,
  recommendResolution: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export default Card
