import { css } from "styled-components"

export default {
  Background: {
    Primary: css`
      background-color: #ffb000d4;
    `,
    White: css`
      background-color: rgb(250,251,253);
    `
  },
  Text: {
    White: css`
      color: rgba(255,255,255,0.85);
    `,
    Dark: css`
      color: #444444;
    `,
  },
}
