import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"
import CircularProgress from "@mui/material/CircularProgress"

import {
  Grid,
  Typography
} from "@mui/material"

import DiscountIcon from "@mui/icons-material/Discount"

import InputStyled from "../../common/styled/InputStyled"
import AlertStyled from "../../common/styled/AlertStyled"
import s from "./ConstructorStyled"

import RadioItem from "../../common/component/RadioItem"
import Button from "../../common/styled/ButtonStyled"

import { isMobile, isDesktop } from "../../../utils/responsive"

function Account({
  id, device, isCreate, countAccounts, error, checkLogin,
  deviceChanged, accountRemoved, loginChanged, applySale
}) {
  const { t } = useTranslation()

  return (
    <s.BlockContainer>
      {(countAccounts > 1 && id === countAccounts - 1)
        && (
          <Button.Close
            onClick={() => accountRemoved(id)}
          />
        )}

      {[
        { text: "Account_Device3", value: 3 },
        { text: "Account_Device1", value: 1 },
        { text: "Account_Device2", value: 2 },
      ].map(({ text, value }) => (
        <RadioItem
          name="device"
          groupId={id.toString()}
          onChange={(val) => deviceChanged(id, val)}
          text={t(text)}
          value={value}
          key={value}
          checked={device === value}
        />
      ))}

      {device != null && (<>
        <s.ControlWrapper>
          {(device === 1 && !isCreate)
            && (
              <InputStyled.Mask
                name="mac"
                type="text"
                mask="**:`**:`**:`**:`**:`**"
                defaultValue="00:1A:79"
                lazy={false}
                overwrite
                capitalize="uppercase"
                prepare={(str) => str.replace(/[^A-Za-z0-9]+/, "")}
                onAccept={(value, target) => loginChanged(
                  id, target.el.input.name,
                  target.unmaskedValue.match(/.{2}/g).join(":").toUpperCase()
                )}
              />
            )}

          {([2, 3].includes(device) && !isCreate)
            && (
              <InputStyled.Text
                name="login"
                type="text"
                placeholder={t("Account_Login")}
                onChange={(e) => loginChanged(id, e.target.name, e.target.value.trim())}
              />
            )}

          {(error.message)
            && (
              <AlertStyled.Inline
                icon={error.processing
                  ? (
                    <CircularProgress
                      size="1.13rem"
                      color="inherit"
                      thickness={4.5}
                      style={{ margin: "0 2px" }}
                    />
                  ) : undefined}
                severity={(() => {
                  if (error.processing) {
                    return "warning"
                  }

                  return error.isValid ? "info" : "error"
                })()}
              >
                {{
                  Account_EndDate: `${t(error.message)} ${error.date}`,
                }[error.message] || t(error.message)}
              </AlertStyled.Inline>
            )}

        </s.ControlWrapper>

        {(checkLogin && checkLogin.saleType)
          && (
            <AlertStyled.Center
              severity="info"
              action={isDesktop && (
                <s.Apply
                  onClick={() => applySale(id)}
                >
                  {t("Account_Apply")}
                </s.Apply>
              )}
              icon={<DiscountIcon />}
            >
              <Grid container fullWidth>
                <Grid>
                  <Typography>{t(`Account_${checkLogin.saleType}_1`)}</Typography>
                  <Typography>{t(`Account_${checkLogin.saleType}_2`)}</Typography>

                  {isMobile && (
                    <s.Apply
                      onClick={() => applySale(id)}
                    >
                      {t("Account_Apply")}
                    </s.Apply>
                  )}
                </Grid>
              </Grid>
            </AlertStyled.Center>
          )}
      </>)}

    </s.BlockContainer>
  )
}

Account.propTypes = {
  countAccounts: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  device: PropTypes.number,
  isCreate: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    isValid: PropTypes.bool,
    message: PropTypes.string,
    date: PropTypes.string,
    processing: PropTypes.bool,
  }).isRequired,
  checkLogin: PropTypes.shape({
    saleType: PropTypes.string
  }),

  deviceChanged: PropTypes.func.isRequired,
  accountRemoved: PropTypes.func.isRequired,
  loginChanged: PropTypes.func.isRequired,
  applySale: PropTypes.func.isRequired,
}

export default Account

