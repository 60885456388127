import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"

import s from "./ChoosePlanStyled"

function Card({
  price: {
    id, currency, name, period, discount
  },
  selectCard,
  selected
}) {
  const { t } = useTranslation()

  return (
    <s.CardContainer
      onClick={() => selectCard(id)}
      focus={selected.toString()}
    >
      {currency && (
        <s.Period>{period.name && t(period.name).toUpperCase()}</s.Period>
      )}
      <s.Price
        discount={discount && discount.toString()}
        currency={currency.toString()}
      >
        {currency && (
          <sup>€</sup>
        )}
        <span>{t(name)}</span>
      </s.Price>

      <s.Buy>{t("ChoosePlan_Buy")}</s.Buy>
    </s.CardContainer >
  )
}

Card.propTypes = {
  price: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    period: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    }),
    currency: PropTypes.bool,
    discount: PropTypes.bool,
  }).isRequired,

  selectCard: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
}

export default Card

