import { css } from "styled-components"

const boxHighlight = css`
  box-shadow: 0 10px 20px rgba(255,176,0,0.3);
`

export default {
  Box: css`
    box-shadow: 0 5px 15px rgba(255,176,0,0.2);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    
    &:hover {
      ${boxHighlight};
    }
  `,
  ContainerTop: css`
    box-shadow: 0 -10px 15px -15px rgba(0, 0, 0, 0.5);
  `,
  BoxHighlight: boxHighlight
}
