import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"
import { shallowEqual, useSelector } from "react-redux"

import s from "./FormStyled"

import ConstructorContainer from "./constructor/ConstructorContainer"

function FormContainer(props) {
  const { t } = useTranslation()

  const {
    plan
  } = useSelector((state) => ({
    plan: state.subscription.plan
  }), shallowEqual)

  return (
    <s.FormContainer id="form-container">
      <s.InfoContainer>
        {(t("Form_Title") !== "")
          && (
            <>
              <s.Title>
                {t("Form_Title")}
              </s.Title>

              <s.Description>
                {t("Form_Description")}
              </s.Description>
            </>
          )}
      </s.InfoContainer>

      {plan != null && (
        <ConstructorContainer />
      )}

    </s.FormContainer>
  )
}

FormContainer.propTypes = {
  plan: PropTypes.shape()
}

export default FormContainer
