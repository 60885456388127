import React from "react"

import { useTranslation } from "react-i18next"

import s from "./HeadlineStyled"

function HeadlineComponent(props) {
  const { t } = useTranslation()

  return (
    <s.HeadlineComponent>
      <s.Title>{t("Headline_Title")}</s.Title>
      <div>
        {t("Headline_Description")}
      </div>
    </s.HeadlineComponent>
  )
}

HeadlineComponent.propTypes = {
}

export default HeadlineComponent
