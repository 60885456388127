import React from "react"

import { useTranslation } from "react-i18next"

import {
  Grid,
  Box
} from "@mui/material"

import { isMobile } from "../../utils/responsive"
import * as cookie from "../../utils/cookie"

import s from "./HeaderStyled"

import HeadlineComponent from "./headline/HeadlineComponent"
import { ConnectComponent } from "./connect/ConnectComponent"

function HeaderContainer(props) {
  const { t } = useTranslation()

  return (
    <s.HeaderContainer>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={isMobile ? 6 : 3}>
          <Box display="flex" justifyContent="center">
            <a
              href={`http://prostotv.com${(
                cookie.get("language") === "ru" ? "/ru" : ""
              )}`}
            >
              <s.Logo
                src="/logo.png"
                alt={t("Header_Logo")}
              />
            </a>
          </Box>
        </Grid>

        <Grid item xs={isMobile ? 3 : 6}>
          <Box display="flex" justifyContent="center">
            <HeadlineComponent />
          </Box>
        </Grid>

        <Grid item xs={isMobile ? 3 : 3}>
          <Box display="flex" justifyContent="center">
            <ConnectComponent />
          </Box>
        </Grid>
      </Grid>
    </s.HeaderContainer>
  )
}

HeaderContainer.propTypes = {
}

export default HeaderContainer
