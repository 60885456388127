import React from "react"
import PropTypes from "prop-types"

import s from "./ConnectStyled"

const ItemComponent = ({ icon, text, link }) => (
  <s.ItemContainer>
    <s.ItemLink href={link}>
      <s.ItemImage icon={icon} />
    </s.ItemLink>
  </s.ItemContainer>
)

export const ConnectComponent = ({ ...props }) => (
  <s.ConnectContainer>
    <ItemComponent
      icon={s.MailIcon}
      text="support@prostotele.vision"
      link="mailto:support@prostotele.vision"
    />

    <ItemComponent
      icon={s.TelegramIcon}
      text="@ProstoTV_FR"
      link="https://t.me/ProstoTV_FR"
    />
  </s.ConnectContainer>
)

ItemComponent.propTypes = {
  icon: PropTypes.arrayOf(PropTypes.string).isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}
