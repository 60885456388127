// import _ from "lodash"
import Immutable from "seamless-immutable"
import * as types from "./types"

const initialState = Immutable({
  plan: null,
  pricesData: [
    {
      id: 1,
      name: "10",
      period: {
        id: 1,
        name: "Checkout_RangePeriod1"
      },
      currency: true
    },
    {
      id: 2,
      name: "30",
      period: {
        id: 3,
        name: "Checkout_RangePeriod3"
      },
      currency: true
    },
    {
      id: 3,
      name: "60",
      period: {
        id: 6,
        name: "Checkout_RangePeriod6"
      },
      currency: true
    },
    {
      id: 4,
      name: "100",
      period: {
        id: 7,
        name: "Checkout_RangePeriod7"
      },
      currency: true
    },
    {
      id: 5,
      name: "200",
      period: {
        id: 8,
        name: "Checkout_RangePeriod8"
      },
      currency: true
    },
    {
      id: 6,
      name: "ChoosePlan_Custom",
      period: {
        id: 1,
      },
      currency: false
    },
  ],
  periods: [
    { id: 1, name: "Checkout_RangePeriod1", value: 10 },
    { id: 2, name: "Checkout_RangePeriod2", value: 20 },
    { id: 3, name: "Checkout_RangePeriod3", value: 30 },
    { id: 4, name: "Checkout_RangePeriod4", value: 40 },
    { id: 5, name: "Checkout_RangePeriod5", value: 50 },
    { id: 6, name: "Checkout_RangePeriod6", value: 60 },
    { id: 7, name: "Checkout_RangePeriod7", value: 100 },
    { id: 8, name: "Checkout_RangePeriod8", value: 200 }
  ]
})

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.PLAN_SELECTED:
      return state.merge({
        plan: { ...action.plan, selected: true },
      })
    case types.CHANGE_PERIOD: {
      return Immutable.setIn(state, ["plan", "period", "id"], action.id)
    }
    case types.CHANGE_PRICE: {
      const { value, discount } = action.price.period

      return state.merge({
        pricesData: state.pricesData.map((p) =>
          p.id === action.price.id ? { ...p, name: value, discount } : p
        ),
        plan: { ...action.plan, selected: true },
        periods: state.periods.map((p) =>
          p.id === action.price.period.id ? { ...p, value: value, discount } : p
        )
      })
    }
    default:
      return state
  }
}

// selectors
