import React from "react"
import PropTypes from "prop-types"

import InputStyled from "../styled/InputStyled"

const RangeItem = ({
  name, value, text, onChange
}) => {
  const id = `${name}-${value}`

  return (
    <InputStyled.RangeLabelItem>
      <InputStyled.Range
        onChange={(e) => onChange(+e.target.value)}
        type="range"
        name="points"
        min="1"
        max="8"
        value={value}
      />
      <label htmlFor={id}>{text}</label>
    </InputStyled.RangeLabelItem>
  )
}

RangeItem.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

export default RangeItem
