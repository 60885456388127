import styled, { } from "styled-components"

export default {
  HeadlineComponent: styled.div`
  `,
  Title: styled.div`
    font-size: 40px;
    margin-bottom: 30px;
  `,
}
