import styled, { css } from "styled-components"
import { IMaskInput } from "react-imask"

import { isMobile, isDesktop } from "../../../utils/responsive"

const text = {
  base: css`
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid rgba(255, 176, 0, 0.5);
    padding: 12px;
    margin: 8px 0;
    box-shadow: 0px 0px 0 rgba(0,0,0,0), inset 1px 2px 4px rgba(255,176,0,0.25);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    box-sizing: border-box;

    &:focus {
      box-shadow: 0px 0px 8px rgba(255,176,0,0.4), inset 0 0 0 rgba(0,0,0,0);
      outline-style: none;
    }
    
    ${({ capitalize }) => capitalize && css`
      text-transform: ${capitalize};
    `};
  `,
}

const sliderTrack = css`
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  height: 6px;
  cursor: pointer;
  background-color: rgba(255,176,0,0.63);
  border-radius: 4px;
`

const sliderThumb = css`
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  border: 1px solid rgba(255,176,0,0.73);
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -11px;
  box-shadow: 0px 0px 0 rgba(0,0,0,0), inset 1px 2px 4px rgba(255,176,0,0.25);
`

const sliderThumbFocus = css`
  box-shadow: 0px 0px 8px rgba(255,176,0,0.4), inset 0 0 0 rgba(0,0,0,0);
`

const trackThumbFocus = css`
  background-color: rgba(255,176,0,0.8);
`

export default {
  Radio: styled.input`
    opacity: 0.6;
  `,
  Checkbox: styled.input`
    opacity: 0.5;
    position: relative;
    left: 2px;
  `,
  Range: styled.input`
    -webkit-appearance: none;
    position: relative;
    top: -3px;

    ${isDesktop && css`
      width: 300px;
    `}

    ${isMobile && css`
      width: 100%;
    `}

    &:focus {
      outline: none;
    }
    &::-moz-focus-outer {
      border: 0;
    }
    &::-webkit-slider-runnable-track {
      ${sliderTrack};
    }
    &::-moz-range-track {
      ${sliderTrack};
    }
    &::-webkit-slider-thumb {
      ${sliderThumb};
    }
    &::-moz-range-thumb {
      ${sliderThumb};
    }
    &:focus::-webkit-slider-runnable-track {
      ${trackThumbFocus};
    }
    &:focus::-moz-range-track {
      ${trackThumbFocus};
    }
    &:focus::-webkit-slider-thumb {
      ${sliderThumbFocus};
    }
    &:focus::-moz-range-thumb {
      ${sliderThumbFocus};
    }
  `,
  Mask: styled(IMaskInput)`
    ${text.base};
    height: 42px;

    ${isDesktop && css`
      width: 250px;
    `}

    ${isMobile && css`
      width: 100%;
    `}
  `,
  Text: styled.input`
    ${text.base};
    height: 42px;

    ${isDesktop && css`
      width: 250px;
    `}

    ${isMobile && css`
      width: 100%;
    `}
  `,
  MultilineText: styled.textarea`
    ${text.base};
    font-family: inherit;
    height: 100px;
    resize: vertical;

    ${isDesktop && css`
      width: 400px;
    `}

    ${isMobile && css`
      width: 100%;
    `}
  `,
  RangeLabelItem: styled.div`
    padding: 16px 0;
    &>label {
      margin-left: 16px;
      font-size: 22px;
    }
  `,
  GroupLabelItem: styled.label`
    display: block;
    position: relative;
    padding-left: 35px;
    margin: 16px 0;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
    &>input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    &>input:checked~span {
      background-color: rgba(255,176,0,0.63);
      box-shadow: 0px 0px 8px rgba(255,176,0,0.4);
    }
    &>input:checked~span:after {
      display: block;
    }
  `,
  Checkmark: styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 4px;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  `,
  Circle: styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
    &:after {
      content: "";
      position: absolute;
      display: none;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: white;
      transform: scale(0.4);
    }
  `,
}
