import styled, { css } from "styled-components"

import Alert from "@mui/material/Alert"

import { isMobile, isDesktop } from "../../../utils/responsive"

export default {
  Inline: styled(Alert)`
    height: 30px;
    align-items: center;
    
    ${isDesktop && css`
      margin: 8px;
      padding: 0 16px;
    `}

    ${isMobile && css`
      margin-bottom: 8px;
      padding: 2px 16px;
    `}
  `,
  Center: styled(Alert)`
    align-items: center;
  `,
}
