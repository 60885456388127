import url from "url"

class PayApi {
  checkLogin = async (name, device, signal) => {
    const res = await this.get(
      "/api/1/pay/check-login",
      { name, device },
      { signal }
    )
    return res
  }

  transaction = async ({
    id, differentIp, description, email, ip, names
  }) => {
    const res = await this.post(
      "/api/1/pay/transaction",
      {
        orderID: id,
        differentIp,
        description,
        email,
        ip,
        names
      }
    )
    return res
  }

  ip = async () => {
    const res = await this.get(
      "/api/1/util/ip"
    )
    return res
  }

  get(pathname, query = {}, options = {}) {
    return this.api(
      { pathname, query },
      {
        method: "GET",
        ...options
      }
    )
  }

  post(pathname, body = {}, options = {}) {
    return this.api(
      { pathname },
      {
        ...options,
        method: "POST",
        body: JSON.stringify({
          ...body
        }),
        headers: {
          ...options.headers,
          "Content-Type": "application/json; charset=utf-8"
        }
      }
    )
  }

  async api(uri, options = {}) {
    const urlFormat = this.urlFormat(uri)
    const res = await fetch(
      urlFormat,
      {
        ...options,
        headers: {
          ...options.headers
        }
      }
    )

    return res.json()
  }

  urlFormat = (params = {}) => {
    const { query, ...other } = params

    return url.format({
      protocol: "https",
      hostname: "videoclub.prostotele.vision",
      // protocol: "http",
      // hostname: "localhost",
      // port: 8080,
      query,
      ...other
    })
  }
}

export default new PayApi()
