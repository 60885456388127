import React from "react"

import { useTranslation } from "react-i18next"
import { useDispatch, shallowEqual, useSelector } from "react-redux"

import {
  Grid
} from "@mui/material"

import { selectPlanAction } from "../../store/subscription/actions"
import { isDesktop } from "../../utils/responsive"

import s from "./ChoosePlanStyled"
import Card from "./Card"

function ChoosePlanContainer(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {
    selectPlan,
    pricesData,
    plan
  } = useSelector((state) => ({
    selectPlan: selectPlanAction,
    pricesData: state.subscription.pricesData,
    plan: state.subscription.plan,
  }), shallowEqual)

  async function selectCard(id) {
    await dispatch(selectPlan(pricesData.find((data) => data.id === id)))
  }

  return (
    <s.ChoosePlanContainer>
      {(t("ChoosePlan_Title") !== "")
        && (
          <s.InfoContainer>
            <s.Title>{t("ChoosePlan_Title")}</s.Title>
            <span>
              {t("ChoosePlan_Description")}
            </span>
          </s.InfoContainer>
        )}
      <s.ChooseCardContainer>
        <Grid container spacing={2}>
          {pricesData && pricesData.map((it) => (
            <Grid
              item
              key={it.id}
              xs={isDesktop ? 4 : 6}
            >
              <Card
                selected={it.id === (plan && plan.id)}
                selectCard={selectCard}
                price={it}
              />
            </Grid>
          ))}
        </Grid>
      </s.ChooseCardContainer>
    </s.ChoosePlanContainer>
  )
}

ChoosePlanContainer.propTypes = {
}

export default ChoosePlanContainer
